<!-- Button -->
<div class="flex flex-row items-center">
  <button
    mat-button
    [matMenuTriggerFor]="userActions"
    class="md:px-4 px-0 md:min-w-[64px] min-w-[40px]"
  >
    <span
      *ngIf="!isScreenSmall"
      class="text-lg font-bold pr-1 md:block hidden"
      >{{ user?.name }}</span
    >
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  </button>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu" class="user-menu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium"
        >{{ user?.principalName }}
        {{ user?.role ? '- ' + user?.role : '' }}</span
      >
    </span>
  </button>
  <div *ngIf="!isSSO">
    <mat-divider class="my-2"></mat-divider>
    <button (click)="onChangePassword()" mat-menu-item>
      <mat-icon [svgIcon]="'heroicons_outline:lock-open'"></mat-icon>
      <span>Change Password</span>
    </button>
  </div>
  <button class="hidden" mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
