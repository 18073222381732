import { Action } from '@ngrx/store';

import { MetadataField } from 'app/components/settings/settings.types';
import { BreadCrumb } from 'app/core/interfaces/bread-crumb';
import { ToolbarSettings } from 'app/core/interfaces/toolbar-settings';
import { Lookup } from 'app/core/models/lookup';

export const CLEAR_SPINNER = 'common/clear-spinner';
export const DELETE_DATA = 'common/delete-data';
export const FILTER_SCHEDULER = 'common/filter-scheduler';
export const GET_ASSETTIMELOGS = 'common/get-assetTimeLogs';
export const HIDE_TOOLBAR = 'common/hide-toolbar';
export const LOAD_SCHEDULER = 'common/load-scheduler';
export const PASS_ASSETTIMELOG_OBJ = 'common/pass-assetTimeLog-object';
export const REFRESH_LIST = 'common/refresh-list';
export const SEND_ASSETTIMELOGS = 'common/send-assetTimeLogs';
export const SET_ALL_METADATA_FIELDS = 'common/all-metadata-fields';
export const SET_BREADCRUMB = 'common/set-breadcrumb';
export const SET_IS_MOBILE_VIEW = 'common/mobile-view';
export const SET_LOWER_TOOLBAR_VISIBILITY = 'common/lower-toolbar-visibility';
export const SET_PAGE_TITLE = 'common/page-title';
export const SET_PROJECT = 'common/project';
export const SET_SELECTED_ITEM_TYPE = 'common/selected-item-type';
export const SET_SUBMISSIONS = 'common/submissions';
export const SHOW_GRID_ACTIONS = 'common/grid-actions';
export const SHOW_TOOLBAR = 'common/show-toolbar';
export const START_SPINNER = 'common/start-spinner';
export const STOP_SPINNER = 'common/stop-spinner';
export const UPDATE_DATA = 'common/update-data';
export const SET_METADATA_FIELDS = 'common/modules-metadata-fields';

export class HideToolbarAction implements Action {
  readonly type = HIDE_TOOLBAR;
  constructor(public payload: ToolbarSettings) {}
}

export class ClearSpinnerAction implements Action {
  readonly type = CLEAR_SPINNER;
}

export class SelectPageTitleAction implements Action {
  readonly type = SET_PAGE_TITLE;
  constructor(public payload: string) {}
}

export class SetIsMobileViewAction implements Action {
  readonly type = SET_IS_MOBILE_VIEW;
  constructor(public payload: boolean) {}
}

export class SetLowerToolbarVisibilityAction implements Action {
  readonly type = SET_LOWER_TOOLBAR_VISIBILITY;
  constructor(public payload: boolean) {}
}

export class StartSpinnerAction implements Action {
  readonly type = START_SPINNER;
}

export class StopSpinnerAction implements Action {
  readonly type = STOP_SPINNER;
}

export class LoadScheduler implements Action {
  readonly type = LOAD_SCHEDULER;
  constructor(public payload: boolean) {}
}

export class FilterScheduler implements Action {
  readonly type = FILTER_SCHEDULER;
  constructor(public payload: any) {}
}

export class RefreshList implements Action {
  readonly type = REFRESH_LIST;
  constructor(public payload: any) {}
}

export class UpdateData implements Action {
  readonly type = UPDATE_DATA;
  constructor(public payload: any) {}
}

export class DeleteData implements Action {
  readonly type = DELETE_DATA;
  constructor(public payload: string) {}
}

export class ShowGridActions implements Action {
  readonly type = SHOW_GRID_ACTIONS;
  constructor(public payload: string) {}
}

export class SetSubmissions implements Action {
  readonly type = SET_SUBMISSIONS;
  constructor(public payload: any[]) {}
}

export class SetBreadCrumb implements Action {
  readonly type = SET_BREADCRUMB;
  constructor(public payload: BreadCrumb[]) {}
}

export class SetProject implements Action {
  readonly type = SET_PROJECT;
  constructor(public payload: Lookup) {}
}
export class SetMetadataFields implements Action {
  readonly type = SET_METADATA_FIELDS;
  constructor(public payload: MetadataField[]) {}
}
export type CommonActions =
  | ClearSpinnerAction
  | DeleteData
  | FilterScheduler
  | LoadScheduler
  | RefreshList
  | SelectPageTitleAction
  | SetBreadCrumb
  | SetIsMobileViewAction
  | SetLowerToolbarVisibilityAction
  | SetProject
  | SetSubmissions
  | ShowGridActions
  | StartSpinnerAction
  | StopSpinnerAction
  | UpdateData
  | SetMetadataFields;
