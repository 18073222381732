import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, filter } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseDrawerService } from '@fuse/components/drawer';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { InitialData } from 'app/app.types';
import { User } from 'app/core/models/auth/user';
import { AuthService } from 'app/core/services/auth.service';
import { FuseDrawerDataService } from 'app/core/services/fuse-drawer-data.service';
import {
  SettingDrawer,
  SettingsDrawerDataService,
} from 'app/core/services/setting-drawer-data.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectUser } from 'app/core/store/selectors';
import { environment } from 'environments/environment';
import { ModuleDrawing } from 'app/core/models/module-drawing';
import { UploadAttachmentDataService } from 'app/core/services/upload-attachment-data.service';
import { UpdateItemAttachmentDataService } from 'app/core/services/update-item-attachment.data.service';

@Component({
  selector: 'modern-layout',
  templateUrl: './modern.component.html',
  styleUrls: ['./modern.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
  data: InitialData;
  isScreenSmall: boolean;
  isPdfOnly: boolean;
  steelChasis: string;
  moduleId: string;
  title: string;
  uploadSaveUrl: string;
  user: User;
  item: any;
  settingDrawerData: SettingDrawer = null;
  drawings: any = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  drawerChipsOptions = [
    {
      name: 'Manage Projects',
      icon: 'mat_outline:grid_view',
      link: '/admin-settings/projects',
    },
    {
      name: 'Manage Users',
      icon: 'heroicons_outline:user-add',
      link: '/admin-settings/users',
    },
  ];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _authService: AuthService,
    private _store: Store<AppState>,
    private _fuseDataSvc: FuseDrawerDataService,
    private _fuseDrawerService: FuseDrawerService,
    private _uploadAttachmentService: UploadAttachmentDataService,
    private _updateItemAttachmentService: UpdateItemAttachmentDataService,
    private _settingDrawerSvc: SettingsDrawerDataService,
    private _router: Router,
  ) {
    this._fuseDataSvc.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(item => {
        this.title = item?.title;
        this.uploadSaveUrl = item?.uploadSaveUrl;
        if (item?.type === 'drawings') {
          this.steelChasis = item?.metadata?.steelChasis;
          this.moduleId = item?.id;
          this.drawings = item?.drawings;
          this.item = item;
          this.isPdfOnly = true;
        } else if (item?.type === 'attachments') {
          this.drawings = item?.attachments;
          this.item = item;
          this.isPdfOnly = false;
        }
      });

    this._settingDrawerSvc.data
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(x => !!x && !!x.drawerType),
      )
      .subscribe(x => {
        this.settingDrawerData = x;
      });
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
    this._authService.restoreAuthState();
    this._activatedRoute.data.subscribe((data: Data) => {
      this.data = data.initialData;
    });

    this._store
      .select(selectUser)
      .pipe(filter(user => !!user))
      .subscribe(user => {
        this.user = user;
        this._changeDetectorRef.markForCheck();
      });

    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleNavigation(name: string): void {
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name,
      );

    if (navigation) {
      navigation.toggle();
    }
  }

  navigateTo = (itemLink: string): void => {
    const drawer = this._fuseDrawerService.getComponent('settingsDrawer');
    drawer.toggle();
    this._router.navigate([itemLink]);
  };

  getHeaderTitle(data: SettingDrawer): string {
    if (!data) return '';

    switch (data.drawerType) {
      case 'production-lines':
        return data.data ? 'Edit Production Line' : 'Add Production Line';
      case 'stations':
        return data.data ? 'Edit Station' : 'Add Station';
      default:
        return '';
    }
  }
  closeUploadDrawings() {
    const drawer = this._fuseDrawerService.getComponent('uploadDrawingDrawer');
    drawer.toggle();
  }
  isAdmin(): boolean {
    return this.user?.role === 'project_admin' || this.user?.role === 'admin';
  }
  handleUpdatedAttachments(data) {
    if (this.item.type === 'attachments') {
      this.item.attachments = data;
      this.drawings = data;
      this._updateItemAttachmentService.changeData({
        updatedAttachments: data,
        item: this.item,
      });
    }
  }
}
