import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { User } from '../models/auth/user';
import { SetUserAction } from '../store/actions/auth.actions';
import { AppState } from '../store/reducers/app.reducer';
import {
  selectIsUserLoading,
  selectUser,
} from '../store/selectors/auth.selectors';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  user: User;
  constructor(
    private store: Store<AppState>,
    private _localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router,
  ) {}

  isRouteAllowed = async (url: string): Promise<boolean> => {
    return this.authService.isLoggedIn;
  };
}
