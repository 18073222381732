import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ClearSpinnerAction,
  StartSpinnerAction,
  StopSpinnerAction,
} from '../store/actions/common.actions';
import { AppState } from '../store/reducers/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  exceptions: string[] = ['disable-checklist', 'enable-checklist'];

  constructor(private store: Store<AppState>) {}

  clear(): void {
    this.store.dispatch(new ClearSpinnerAction());
  }

  start(url: string) {
    if (this.exceptions.some(e => url.includes(e))) {
      return;
    }

    this.store.dispatch(new StartSpinnerAction());
  }

  stop(url: string) {
    if (this.exceptions.some(e => url.includes(e))) {
      return;
    }

    this.store.dispatch(new StopSpinnerAction());
  }
}
