import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';

import { environment } from 'environments/environment';

import { PostProductionLine, ProductionLine } from '../models/production-line';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProductionLinesService extends BaseService<ProductionLine> {
  constructor(protected http: HttpClient) {
    super(http, 'ProductionLines');
  }

  private productionLineUpdatedSub = new Subject();

  productionLineUpdated$ = this.productionLineUpdatedSub.asObservable();

  protected get apiBaseUrl(): string {
    return `${environment.apiBaseUrl}`;
  }

  getByProject(projectId: string): Observable<ProductionLine[]> {
    return this.http.get<ProductionLine[]>(
      `${this.apiBaseUrl}/ProductionLines/by-project/${projectId}`,
    );
  }

  addProductionLine(production: PostProductionLine) {
    return this.http
      .post(`${this.apiBaseUrl}/ProductionLines`, production)
      .pipe(tap(resp => this.productionLineUpdatedSub.next(resp)));
  }

  updateProductionLine(projectId: string, productionLine: ProductionLine) {
    return this.http
      .put<void>(
        `${this.apiBaseUrl}/ProductionLines/${productionLine.id}?partitionKey=${projectId}`,
        productionLine,
      )
      .pipe(tap(resp => this.productionLineUpdatedSub.next(resp)));
  }
  enableProductionLine(
    projectId: string,
    stationId: string,
  ): Observable<ProductionLine[]> {
    return this.http.get<ProductionLine[]>(
      `${this.apiBaseUrl}/ProductionLines/${projectId}/${stationId}/enable`,
    );
  }
  disableProductionLine(
    projectId: string,
    stationId: string,
  ): Observable<ProductionLine[]> {
    return this.http.get<ProductionLine[]>(
      `${this.apiBaseUrl}/ProductionLines/${projectId}/${stationId}/disable`,
    );
  }
}
