import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateItemAttachmentDataService {
  private dataSource = new BehaviorSubject(null);

  data = this.dataSource.asObservable();

  constructor() {}

  changeData(data): void {
    this.dataSource.next(data);
  }
}
