import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { Attachment } from '../models/attachment';
import { AppState } from '../store/reducers/app.reducer';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  protected get apiBaseUrl(): string {
    return `${environment.apiBaseUrl}`;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
  ) {}

  downloadItemImportTemplate(): Observable<any> {
    return this.http.request(
      'get',
      `${this.apiBaseUrl}/tracker/download-template`,
      {
        responseType: 'blob',
      },
    );
  }

  uploadAttachments(file: File, fileName: string): Observable<Attachment> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return this.http.post<any>(`${this.apiBaseUrl}/Blob/upload`, formData);
  }
}
