import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthGuardService } from '../services/auth-guard.service';
import { AppState } from '../store/reducers/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private authGuardService: AuthGuardService,
    private router: Router,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const canActivate = await this.authGuardService.isRouteAllowed(state.url);

    if (canActivate) {
      // console.log(
      // "Route: " +
      //   route.url +
      //   (!canActivate ? " not" : "") +
      //   " allowed!"
      // );
    } else {
      this.router.navigate([`/auth/login`], {
        queryParams: { returnUrl: state.url },
      });
    }

    return canActivate;
  }
}
