import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService implements OnInit {
  private resizeSubject = new Subject<ScreenSize>();

  public resize$ = this.resizeSubject.asObservable();
  public screenSize: ScreenSize = 'large';

  constructor() {
    this.updateScreenSize(window.innerWidth);
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  private onResize(event: any) {
    this.updateScreenSize(event.target.innerWidth);
  }

  private updateScreenSize(screenWidth: number) {
    const screenSize: ScreenSize = screenWidth < 768 ? 'small' : 'large';
    this.resizeSubject.next(screenSize);
    this.screenSize = screenSize;
  }
}

export type ScreenSize = 'small' | 'large';
