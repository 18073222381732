<ng-container *ngIf="showLoader">
  <div class="spinner_overlay">
    <div class="spinner_center">
      <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
    </div>
  </div>
</ng-container>

<ng-template #progressSpinner>
  <mat-progress-spinner [diameter]="diameter" [mode]="mode" [color]="color" [strokeWidth]="strokeWidth" [value]="value">
  </mat-progress-spinner>
</ng-template>