import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SplitContainerDirective } from './split-container.directive';
import { SplitContentDirective } from './split-content.directive';
import { SplitSecondaryContentDirective } from './split-secondary-content.directive';
import { StopPropagationDirective } from './stop-propagation.directive';

@NgModule({
  declarations: [
    SplitContainerDirective,
    SplitContentDirective,
    SplitSecondaryContentDirective,
    StopPropagationDirective,
  ],
  imports: [CommonModule],
  exports: [
    SplitContainerDirective,
    SplitContentDirective,
    SplitSecondaryContentDirective,
    StopPropagationDirective,
  ],
})
export class DirectivesModule {}
