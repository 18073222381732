import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { SpinnerService } from '../services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private spinnerSvc: SpinnerService,
  ) {}

  protected get apiBaseUrl(): string {
    return `${environment.apiBaseUrl}`;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.indexOf(this.apiBaseUrl) !== -1 &&
      request.url.indexOf(`/auth/token`) === -1 &&
      request.url.indexOf(`/auth/refresh`) === -1 &&
      request.url.indexOf('auth/login') === -1
    ) {
      if (this._authService.token && this._authService.tokenExpired) {
        return this._authService.refreshAuthToken().pipe(
          switchMap(x => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this._authService.token}`,
              },
            });

            return next.handle(request).pipe(
              finalize(() => {
                this.spinnerSvc.stop(request.url);
              }),
            );
          }),
        );
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this._authService.token}`,
          },
        });

        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }
}
