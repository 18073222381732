export const environment = {
  production: false,
  apiBaseUrl: 'https://qualityapi-dev.linq-modular.com',
  scope: 'api://quality.linq-modular.com/user_impersonation',
  baseUrl: 'https://quality-dev.linq-modular.com',
  azureAd: {
    clientId: 'e3f51cbf-77cc-4800-ab05-32c065ecd85d',
    authority: 'https://login.microsoftonline.com/alec4.onmicrosoft.com',
    redirectUri: '/',
    apiClientId: '16fd9d48-eb8d-4c45-ac6f-d08b700bd853',
  },
};
