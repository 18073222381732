import { createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducer';
import { AppState } from '../reducers/app.reducer';

export const selectAuth = (state: AppState) => state.auth;

export const selectIsUserLoading = createSelector(
  selectAuth,
  (state: fromAuth.IState) => state.isLoadingUser,
);

export const selectUser = createSelector(
  selectAuth,
  (state: fromAuth.IState) => state.user,
);

export const selectRole = createSelector(
  selectAuth,
  (state: fromAuth.IState) => (state.user ? state.user.role : ''),
);

// export const selectUserSettings = createSelector(
//   selectAuth,
//   (state: fromAuth.IState) => state.user.accessManagement
// );

export const selectLoginErrorMessage = createSelector(
  selectAuth,
  (state: fromAuth.IState) => state.loginErrorMessage,
);

export const selectLoginResult = createSelector(
  selectAuth,
  (state: fromAuth.IState) => state.loginResult,
);

export const selectUserLogin = createSelector(
  selectAuth,
  (state: fromAuth.IState) => state.loggedInAccount,
);

// export const selectUserProjects = createSelector(
//   selectAuth,
//   (state: fromAuth.IState) => state.user.projects
// );
