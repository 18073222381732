<!-- <kendo-upload
  #upload
  class="upload-ctrl"
  [multiple]="true"
  [saveUrl]="uploadSaveUrl"
  [withCredentials]="false"
  (success)="successEventHandler($event)"
  (upload)="uploadEventHandler($event)"
  *ngIf="canUpload"
>
</kendo-upload> -->
<kendo-upload
  #upload
  class="upload-ctrl"
  [multiple]="true"
  [saveUrl]="uploadSaveUrl"
  [withCredentials]="false"
  (success)="successEventHandler($event)"
  (upload)="uploadEventHandler($event)"
  *ngIf="canUpload && item?.type === 'drawings'"
  (select)="selectEventHandler($event)"
>
  <input type="file" kendoUploadSelect [accept]="'.pdf'" />
</kendo-upload>

<!-- upload.component.html -->
<div *ngIf="canUpload && item?.type === 'attachments'">
  <input
    class="selectFile"
    hidden
    type="file"
    id="fileUpload"
    multiple
    (change)="handleFileChange($event)"
  />
  <label for="fileUpload">
    <mat-icon
      svgIcon="mat_outline:cloud_upload"
      class="upload-icon-custom mr-2"
    ></mat-icon>
    <span>Select File to Upload</span>
  </label>
  <div>
    <div class="selectedFiles">
      <div
        class="imgCols"
        *ngFor="let file of getImageFiles(selectedFiles); index as i"
      >
        <div class="img-wrapper aspect-[9/6]">
          <img
            *ngIf="file.dataUrl"
            [src]="file.dataUrl"
            alt="{{ file.name }}"
          />
          <div class="delete-icon-container">
            <button
              mat-icon-button
              (click)="deleteAttachment(file, i)"
              class="bg-white text-red text-icon shadow-none min-h-[30px] h-[30px] w-[30px] rounded-full inline-flex items-center justify-center"
            >
              <mat-icon
                svgIcon="mat_outline:delete"
                class="text-red-600"
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="pdfList">
      <div *ngFor="let file of getPdfFiles(selectedFiles); index as k">
        <div class="colBox">
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 32 32"
            >
              <path
                fill="#909090"
                d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945L24.1 2.072"
              />
              <path
                fill="#f4f4f4"
                d="M24.031 2H8.808v27.928h20.856V7.873L24.03 2"
              />
              <path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5H8.655" />
              <path
                fill="#dd2025"
                d="M22.472 10.211H2.395V3.379h20.077v6.832"
              />
              <path
                fill="#464648"
                d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2.042 2.042 0 0 0 .647-.117a1.427 1.427 0 0 0 .493-.291a1.224 1.224 0 0 0 .335-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.409-.104a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
              />
              <path
                fill="#dd2025"
                d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511Zm-2.357.083a7.543 7.543 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14.216 14.216 0 0 0 1.658 2.252a13.033 13.033 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.787 10.787 0 0 1-.517 2.434a4.426 4.426 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444ZM25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14.228 14.228 0 0 0-2.453.173a12.542 12.542 0 0 1-2.012-2.655a11.76 11.76 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.309 9.309 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.622 9.622 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a22.549 22.549 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
              />
              <path fill="#909090" d="M23.954 2.077V7.95h5.633l-5.633-5.873Z" />
              <path fill="#f4f4f4" d="M24.031 2v5.873h5.633L24.031 2Z" />
              <path
                fill="#fff"
                d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2.042 2.042 0 0 0 .647-.117a1.428 1.428 0 0 0 .493-.291a1.224 1.224 0 0 0 .332-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.411-.105a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
              />
            </svg>
          </div>
          <div class="relative">
            <span class="text-sm font-medium">{{ file.name }}</span>
          </div>
          <div class="cta">
            <button
              mat-icon-button
              (click)="deleteAttachment(file, k)"
              class="bg-red-100 text-red shadow-none min-h-[34px] h-[34px] w-[34px] rounded-full inline-flex items-center justify-center"
            >
              <mat-icon
                svgIcon="mat_outline:delete"
                class="text-red-400"
              ></mat-icon>
            </button>
            <!-- <button mat-icon-button class="bg-blue-100 text-red shadow-none min-h-[34px] h-[34px] w-[34px]">
          <mat-icon svgIcon="mat_outline:file_download" class="text-blue-400"></mat-icon>
        </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cta-footer">
    <button
      class="btn btn-primary"
      *ngIf="selectedFiles.length > 0"
      (click)="onUpload()"
    >
      Upload
    </button>
  </div>
</div>

<!-- <div>
  <ng-container *ngIf="filerImages(attachments).length > 0">
    <div class="card-header px-3">
      <button
        mat-mini-fab
        class="bg-[#e1e2e5] shadow-none min-h-[34px] h-[34px] w-[34px] rounded-full inline-flex items-center justify-center"
      >
        <i class="w-[18px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{1.5}"
            stroke="currentColor"
            class="w-[18px] h-[18px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        </i>
      </button>
      <span>Photos</span>
    </div>
    <div class="p-3">
      <app-image-viewer [images]="attachments"></app-image-viewer>
    </div>
  </ng-container>
</div> -->
<div>
  <div
    *ngIf="
      item?.type == 'drawings'
        ? filterPDFs(attachments).length > 0
        : filterPDFs(attachments).length > 0 && item?.type == 'drawings'
    "
  >
    <div class="card-header px-3">
      <button
        mat-mini-fab
        class="bg-[#e1e2e5] shadow-none min-h-[34px] h-[34px] w-[34px] rounded-full inline-flex items-center justify-center"
      >
        <i class="w-[18px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-[18px] h-[18px]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
        </i>
      </button>
      <span>Files</span>
    </div>

    <div class="px-3 pb-3">
      <app-pdf-view
        [attachments]="attachments"
        [type]="item?.type"
        (updatedAttachmentsChange)="handleUpdatedAttachments($event)"
      ></app-pdf-view>
    </div>
  </div>
</div>

<!-- <div class="pdfList">
  <ng-container *ngFor="let attachment of attachments">
    <div class="colBox">
      <div class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 32 32"
        >
          <path
            fill="#909090"
            d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945L24.1 2.072"
          />
          <path
            fill="#f4f4f4"
            d="M24.031 2H8.808v27.928h20.856V7.873L24.03 2"
          />
          <path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5H8.655" />
          <path fill="#dd2025" d="M22.472 10.211H2.395V3.379h20.077v6.832" />
          <path
            fill="#464648"
            d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2.042 2.042 0 0 0 .647-.117a1.427 1.427 0 0 0 .493-.291a1.224 1.224 0 0 0 .335-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.409-.104a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
          />
          <path
            fill="#dd2025"
            d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511Zm-2.357.083a7.543 7.543 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14.216 14.216 0 0 0 1.658 2.252a13.033 13.033 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.787 10.787 0 0 1-.517 2.434a4.426 4.426 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444ZM25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14.228 14.228 0 0 0-2.453.173a12.542 12.542 0 0 1-2.012-2.655a11.76 11.76 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.309 9.309 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.622 9.622 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a22.549 22.549 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
          />
          <path fill="#909090" d="M23.954 2.077V7.95h5.633l-5.633-5.873Z" />
          <path fill="#f4f4f4" d="M24.031 2v5.873h5.633L24.031 2Z" />
          <path
            fill="#fff"
            d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2.042 2.042 0 0 0 .647-.117a1.428 1.428 0 0 0 .493-.291a1.224 1.224 0 0 0 .332-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.411-.105a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
          />
        </svg>
      </div>
      <div class="relative">
        <span class="text-sm font-medium">{{ attachment.name }}</span>
      </div>
      <div class="cta">
        <button
          mat-icon-button
          *ngIf="canDelete"
          (click)="deleteDrawing(attachment)"
          class="bg-red-100 text-red shadow-none min-h-[34px] h-[34px] w-[34px] rounded-full inline-flex items-center justify-center"
        >
          <mat-icon
            svgIcon="mat_outline:delete"
            class="text-red-400"
          ></mat-icon>
        </button>
        <a
          href="{{ attachment.fileUri }}"
          target="_blank"
          mat-icon-button
          class="bg-blue-100 text-red shadow-none min-h-[34px] h-[34px] w-[34px] rounded-full inline-flex items-center justify-center"
        >
          <mat-icon
            svgIcon="mat_outline:file_download"
            class="text-blue-400"
          ></mat-icon>
        </a>
      </div>
    </div>
  </ng-container>
</div> -->
