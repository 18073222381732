import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';

import { PaginatedResult } from 'app/core/interfaces/pagingated-result';
import { IItemLog } from 'app/core/interfaces/tracker/itemLog.interface';
import { IQrCodeItem } from 'app/core/interfaces/tracker/qrCodeItem.interface';
import { ITrackerView } from 'app/core/interfaces/tracker/trackerView.interface';
import { Module } from 'app/core/models/module';
import { StatsResponse } from 'app/core/models/stats-response';
import { BaseService, Filter } from 'app/core/services/base.service';
import { environment } from 'environments/environment';

import { Inspection } from '../models/inspection';
import { ModuleDrawing } from '../models/module-drawing';
import {
  SubmitModuleInspectionRequest,
  UpdateModuleInspectionRequest,
} from '../models/submit-module-inspection-request';

@Injectable({
  providedIn: 'root',
})
export class ModuleService extends BaseService<Module> {
  constructor(http: HttpClient) {
    super(http, 'module');
  }

  protected get apiBaseUrl(): string {
    return `${environment.apiBaseUrl}`;
  }

  deleteDrawing(projectId: string, moduleId: string, id: string) {
    return this.http.delete(
      `${this.apiBaseUrl}/module/${moduleId}/drawings/${id}?projectId=${projectId}`,
    );
  }

  getDrawings(
    projectId: string,
    moduleId: string,
  ): Observable<ModuleDrawing[]> {
    return this.http.get<ModuleDrawing[]>(
      `${this.apiBaseUrl}/module/${moduleId}/drawings?projectId=${projectId}`,
    );
  }

  getInspections(projectId: string, moduleId: string) {
    return this.http.get<Inspection[]>(
      `${this.apiBaseUrl}/module/inspections/${projectId}/${moduleId}`,
    );
  }
  getInspectionsByStationGroup(projectId: string, moduleId: string) {
    return this.http.get<Inspection[]>(
      `${this.apiBaseUrl}/module/inspections-group/${projectId}/${moduleId}`,
    );
  }

  getModules(
    projectId: string,
    searchText: string = '',
    take: number = 100,
    skip: number = 0,
    sortField: string = '',
    sortDir: string = '',
    compositeFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    },
  ): Observable<PaginatedResult<ITrackerView>> {
    const filters: Filter[] = [];
    this.appendFilters(filters, compositeFilter);

    if (projectId) {
      filters.push({
        field: 'projectId',
        operator: 'eq',
        value: projectId,
      });
    }

    const params = {
      skip,
      take,
      searchText,
      sortField,
      sortDir,
      filters: JSON.stringify(filters),
    };

    return this.http.get<PaginatedResult<ITrackerView>>(
      `${this.apiBaseUrl}/module`,
      { params },
    );
  }

  getLogs(itemId: number): Observable<IItemLog[]> {
    return this.http.get<IItemLog[]>(`${this.apiBaseUrl}/item-logs/${itemId}`);
  }

  getModuleByQrCode(projectId: string, qrCode: string): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Module/project/${projectId}/by-qr-code?qrCode=${qrCode}`,
    );
  }

  getItem(id: number): Observable<ITrackerView> {
    return this.http.get<ITrackerView>(`${this.apiBaseUrl}/module/${id}`);
  }
  getModule(id: string, projectId: string): Observable<ITrackerView> {
    return this.http.get<ITrackerView>(
      `${this.apiBaseUrl}/Module/${id}?partitionKey=${projectId}`,
    );
  }

  getQrCodeItems(
    projectId: string,
    text: string = '',
    sortField: string = '',
    sortDir: string = '',
    compositeFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    },
  ): Observable<IQrCodeItem[]> {
    const filters: Filter[] = [];
    this.appendFilters(filters, compositeFilter);
    const params = {
      text,
      sortField,
      sortDir,
      filters: JSON.stringify(filters),
    };

    return this.http.get<IQrCodeItem[]>(
      `${this.apiBaseUrl}/module/project/${projectId}/qr-codes`,
      { params },
    );
  }

  getQrCodeItem(projectId: string, id: string): Observable<IQrCodeItem> {
    return this.http.get<IQrCodeItem>(
      `${this.apiBaseUrl}/module/project/${projectId}/qr-codes/${id}`,
    );
  }

  getSelectedQrCodeItems(
    projectId: string,
    ids: string[],
  ): Observable<IQrCodeItem[]> {
    return this.http.post<IQrCodeItem[]>(
      `${this.apiBaseUrl}/module/project/${projectId}/selected-qr-codes`,
      {
        moduleIds: ids,
      },
    );
  }

  getModuleStats(projectId: string = null): Observable<StatsResponse> {
    return this.http.get<StatsResponse>(
      `${this.apiBaseUrl}/module/stats/${projectId}`,
    );
  }

  submitInspection(inspection: SubmitModuleInspectionRequest) {
    return this.http.post(`${this.apiBaseUrl}/module/inspection`, inspection);
  }
  updateInspection(inspection: UpdateModuleInspectionRequest) {
    return this.http.put(`${this.apiBaseUrl}/module/inspection`, inspection);
  }

  uploadItems(projectId: string, data: string[]): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}/module/bulk-upload`, {
      projectId,
      data,
    });
  }
  getReport(projectId: string, moduleId: string): Observable<ArrayBuffer> {
    const url = `${this.apiBaseUrl}/module/${moduleId}/report?projectId=${projectId}`;
    return this.http.get(url, {
      responseType: 'arraybuffer',
    }) as Observable<ArrayBuffer>;
  }
}
