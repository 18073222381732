import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { LOCAL_STORAGE_KEYS } from '../constants/local-storage-keys';
import { Lookup } from '../models/lookup';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  protected get apiBaseUrl(): string {
    return `${environment.apiBaseUrl}`;
  }

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  getProjectLookup(): Observable<Lookup[]> {
    return this.http.get<Lookup[]>(`${this.apiBaseUrl}/projects/lookup`);
  }

  public get project(): Lookup {
    return this.localStorageService.get(LOCAL_STORAGE_KEYS.SelectedProject);
  }
}
