import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LOCAL_STORAGE_KEYS } from 'app/core/constants/local-storage-keys';
import { environment } from 'environments/environment';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { JWT_CLAIMS } from '../constants/claim-types';
import { PaginatedResult } from '../interfaces/pagingated-result';
import { ChangePassword } from '../models/auth/change-password';
import { LoginResponse } from '../models/auth/login-response';
import { Role } from '../models/auth/role';
import { User } from '../models/auth/user';
import { UserView } from '../models/auth/userView';
import { ForgotPassword } from '../models/forgot-password';
import { ResetPassword } from '../models/reset-password';
import {
  LoadUserAction,
  SetLoginErrorMessageAction,
  SetLoginResultAction,
  SetUserAction,
} from '../store/actions/auth.actions';
import { AppState } from '../store/reducers/app.reducer';
import { selectUser } from '../store/selectors';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected get apiBaseUrl(): string {
    return `${environment.apiBaseUrl}`;
  }

  isRefreshTokenInProgress = false;
  refreshTokenSubject: Subject<LoginResponse> = new Subject<LoginResponse>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
  ) {}

  getUsername(): Observable<string> {
    return this.store.select(selectUser).pipe(map(x => x && x.principalName));
  }

  getRole(): Observable<any> {
    let role: any;

    if (this.isLoggedIn) {
      role = this.claims[JWT_CLAIMS.Role];
    }

    return of(role);
  }

  createUser(user: User): Observable<void> {
    return this.http.post<void>(`${this.apiBaseUrl}/auth/register`, user);
  }

  deleteUser(userId: string, projectId: string) {
    return this.http.delete<void>(
      `${this.apiBaseUrl}/auth/users/${userId}?projectId=${projectId}`,
    );
  }

  updateUser(userId: string, user: User) {
    return this.http.put<void>(`${this.apiBaseUrl}/auth/users/${userId}`, user);
  }

  getUsers(): Observable<PaginatedResult<UserView>> {
    return this.http.get<PaginatedResult<UserView>>(
      `${this.apiBaseUrl}/auth/users`,
    );
  }

  getUserByPrincipalName(principalName: string): Observable<User> {
    this.store.dispatch(new LoadUserAction(true));
    return this.http
      .get<User>(`${this.apiBaseUrl}/auth/user/${principalName}`)
      .pipe(
        tap((user: User) => {
          this.store.dispatch(new LoadUserAction(false));
          return user;
        }),
      );
  }

  login(username: string, password: string) {
    return this.http
      .post<LoginResponse>(`${environment.apiBaseUrl}/auth/login`, {
        username,
        password,
      })
      .pipe(
        map(loginResponse => {
          if (loginResponse) {
            this.setAuthToken(loginResponse);
            this.store.dispatch(new SetUserAction(loginResponse.user));
            // this.startRefreshTokenTimer();
          }

          return loginResponse;
        }),
        catchError(error => {
          this.store.dispatch(new SetLoginResultAction(false));

          return of({
            user: '',
            token: '',
            refreshToken: '',
          });
          // return throwError(() => new Error(error));
        }),
      );
  }

  ssoLogin() {
    return this.http
      .post<LoginResponse>(`${environment.apiBaseUrl}/auth/token`, {})
      .pipe(
        catchError(error => {
          this.logout();
          this.router.navigate(['auth/login']);
          this.store.dispatch(new SetLoginResultAction(false));

          const errorMessage =
            error?.status === 400 && error?.error
              ? error.error
              : 'Failed to login with SSO';
          this.store.dispatch(new SetLoginErrorMessageAction(errorMessage));
          return throwError(() => new Error(errorMessage));
        }),
        map(loginResponse => {
          if (loginResponse) {
            this.setAuthToken(loginResponse);
            this.store.dispatch(new SetUserAction(loginResponse.user));
          }

          return loginResponse;
        }),
      );
  }

  changePassword(changePassword: ChangePassword): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiBaseUrl}/auth/change-password`,
      changePassword,
    );
  }

  logout() {
    this.removeAuthToken();
    this.stopRefreshTokenTimer();
    this.router.navigate(['auth/logged-out']);
  }

  refreshAuthToken() {
    if (!this.isRefreshTokenInProgress) {
      // console.log("refreshAuthToken");
      this.isRefreshTokenInProgress = true;
      this.http
        .post<LoginResponse>(`${environment.apiBaseUrl}/auth/refresh`, {
          token: this.token,
          refreshToken: this.refreshToken,
        })
        .pipe(
          map(loginResponse => {
            if (!loginResponse || !loginResponse.token) {
              this.logout();
              return;
            }

            this.setAuthToken(loginResponse);
            // this.startRefreshTokenTimer();
            return loginResponse;
          }),
        )
        .toPromise()
        .then(response => {
          this.refreshTokenSubject.next(response);
          this.isRefreshTokenInProgress = false;
        });
    } else {
      // console.log("refresh is already in progress");
    }

    return this.refreshTokenSubject.asObservable();
  }

  restoreAuthState() {
    if (this.token) {
      if (this.tokenExpired) {
        this.refreshAuthToken().subscribe(loginResponse => {
          this.store.dispatch(new SetUserAction(loginResponse.user));
          // this.startRefreshTokenTimer();
        });
      } else {
        this.getUserByPrincipalName(this.principalNameClaim).subscribe(user => {
          this.store.dispatch(new SetUserAction(user));
        });
      }
    }
  }

  get isLoggedIn() {
    return !!this.token;
  }

  get claims() {
    if (this.isLoggedIn) {
      return JSON.parse(atob(this.token.split('.')[1]));
    }

    return {};
  }

  get principalNameClaim() {
    return this.claims[JWT_CLAIMS.UniqueName];
  }

  get roleClaim() {
    return this.claims[JWT_CLAIMS.Role];
  }

  get tokenExpired(): boolean {
    const expiry = this.claims[JWT_CLAIMS.Expiry];
    const now = Math.floor(Date.now() / 1000);
    return expiry <= now;
  }

  public get token(): string {
    return this.localStorageService.get(LOCAL_STORAGE_KEYS.AuthToken);
  }

  public get isSSO(): boolean {
    if (this.isLoggedIn) return this.claims[JWT_CLAIMS.IsSSO];
  }

  public get refreshToken() {
    return this.localStorageService.get(LOCAL_STORAGE_KEYS.RefreshAuthToken);
  }

  forgotPassword(forgotPassword: ForgotPassword): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiBaseUrl}/auth/forgot-password`,
      forgotPassword,
    );
  }

  isValidResetPasswordToken(token: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiBaseUrl}/auth/validate-reset-password-token?token=${token}`,
      null,
    );
  }

  resetPassword(resetPassword: ResetPassword): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(
        `${this.apiBaseUrl}/auth/reset-password`,
        resetPassword,
      )
      .pipe(tap(response => this.setAuthToken(response)));
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.apiBaseUrl}/roles/lookup`);
  }

  private setToken(token) {
    this.localStorageService.set(LOCAL_STORAGE_KEYS.AuthToken, token);
  }

  private setRefreshToken(refreshToken) {
    this.localStorageService.set(
      LOCAL_STORAGE_KEYS.RefreshAuthToken,
      refreshToken,
    );
  }

  private setAuthToken(loginResponse: LoginResponse): LoginResponse {
    if (loginResponse && loginResponse.token) {
      this.setToken(loginResponse.token);
      this.setRefreshToken(loginResponse.refreshToken);
    }

    return loginResponse;
  }

  private removeAuthToken() {
    this.localStorageService.clear();
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    const jwtToken = JSON.parse(atob(this.token.split('.')[1]));

    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshAuthToken().subscribe(),
      timeout,
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
