import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Attachment } from 'app/core/models/attachment';
import { User } from 'app/core/models/auth/user';
import { Lookup } from 'app/core/models/lookup';
import { ModuleDrawing } from 'app/core/models/module-drawing';
import { ModuleService } from 'app/core/services/modules.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject, selectUser } from 'app/core/store/selectors';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss'],
})
export class PdfViewComponent implements OnInit {
  @Input() attachments: any = [];
  @Output() updatedAttachmentsChange: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();
  @Input() type: string = '';
  @Input() canDelete: boolean = false;
  public currentUser: User;
  public selectedProject: Lookup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private store: Store<AppState>,
    private moduleSvc: ModuleService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    this.store.select(selectUser).subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectProject)
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(project => !!project),
      )
      .subscribe(project => {
        this.selectedProject = project;
      });
  }
  isAdmin(): boolean {
    return (
      this.currentUser?.role === 'project_admin' ||
      this.currentUser?.role === 'admin'
    );
  }

  public deleteFile(item) {
    if (this.type === 'drawings') {
      this.deleteDrawing(item);
    } else if (this.type === 'attachments') {
      this.deleteAttachment(item);
    }
  }

  public deleteDrawing(drawing) {
    this.moduleSvc
      .deleteDrawing(this.selectedProject.id, drawing.moduleId, drawing.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        const updatedAttachments = this.attachments.filter(
          x => x.id !== drawing.id,
        );
        this.ngZone.run(() => {
          this.attachments = [...updatedAttachments];
          this.updatedAttachmentsChange.emit(this.attachments);
        });
      });
  }
  public deleteAttachment(item) {
    const updatedAttachments = this.attachments.filter(
      x => x.fileUri !== item.fileUri,
    );
    this.attachments = [...updatedAttachments];
    this.updatedAttachmentsChange.emit(this.attachments);
  }
  filerImages(items) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];

    const imageAttachments = items.filter(attachment => {
      let urlParts = [];
      if (attachment.fileUri) {
        urlParts = attachment.fileUri.split('.');
      } else if (attachment.url) {
        urlParts = attachment.url.split('.');
      }
      const fileExtension =
        urlParts.length > 1 ? `.${urlParts.pop()?.toLowerCase()}` : '';
      return imageExtensions.includes(fileExtension);
    });
    return imageAttachments;
  }

  filterPDFs(items) {
    const imageExtensions = ['.pdf'];

    const imageAttachments = items.filter(attachment => {
      let urlParts = [];
      if (attachment.fileUri) {
        urlParts = attachment.fileUri.split('.');
      } else if (attachment.url) {
        urlParts = attachment.url.split('.');
      }
      const fileExtension =
        urlParts.length > 1 ? `.${urlParts.pop()?.toLowerCase()}` : '';
      return imageExtensions.includes(fileExtension);
    });
    return imageAttachments;
  }
}
