import { MetadataField } from 'app/components/settings/settings.types';
import { LOCAL_STORAGE_KEYS } from 'app/core/constants/local-storage-keys';
import { BreadCrumb } from 'app/core/interfaces/bread-crumb';
import { ClientConfig } from 'app/core/models/client-config';
import { Lookup } from 'app/core/models/lookup';
import * as CommonActions from '../actions/common.actions';

export interface IState {
  breadCrumbs: BreadCrumb[];
  clientConfig: ClientConfig;
  inProgress: boolean;
  isMobileView: boolean;
  selectedProject: Lookup;
  showGridActions: string;
  spinnersCount: number;
  submissions: any[];
  metadataFields: MetadataField[];
}

const initialState: IState = {
  breadCrumbs: [],
  clientConfig: { itemTypes: [] },
  inProgress: false,
  isMobileView: false,
  selectedProject: {
    id: null,
    name: 'All',
    value: 'All',
    isHavingStage: false,
    headingName: '',
  },
  showGridActions: '',
  spinnersCount: 0,
  submissions: [],
  metadataFields: [],
};

const prevSelectedProject = localStorage.getItem(
  LOCAL_STORAGE_KEYS.SelectedProject,
);
if (prevSelectedProject) {
  initialState.selectedProject = JSON.parse(prevSelectedProject);
}

export const reducer = (
  state = initialState,
  action: CommonActions.CommonActions,
): IState => {
  switch (action.type) {
    case CommonActions.CLEAR_SPINNER:
      return {
        ...state,
        spinnersCount: 0,
        inProgress: false,
      };

    case CommonActions.START_SPINNER:
      return {
        ...state,
        spinnersCount: state.spinnersCount + 1,
        inProgress: true,
      };

    case CommonActions.STOP_SPINNER:
      const spinnersCount = Math.max(0, state.spinnersCount - 1);
      return {
        ...state,
        spinnersCount,
        inProgress: spinnersCount > 0,
      };

    case CommonActions.SET_IS_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.payload,
      };

    case CommonActions.SHOW_GRID_ACTIONS:
      return {
        ...state,
        showGridActions: action.payload,
      };

    case CommonActions.SET_BREADCRUMB:
      return {
        ...state,
        breadCrumbs: action.payload,
      };

    case CommonActions.SET_PROJECT:
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SelectedProject,
        JSON.stringify(action.payload),
      );

      return {
        ...state,
        selectedProject: action.payload,
      };
    case CommonActions.SET_METADATA_FIELDS:
      return {
        ...state,
        metadataFields: action.payload,
      };

    default:
      return state;
  }
};
