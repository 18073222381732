import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseDrawerService } from '@fuse/components/drawer';
import { Store } from '@ngrx/store';
import {
  SuccessEvent,
  UploadComponent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { ITrackerView } from 'app/core/interfaces/tracker/trackerView.interface';
import { Attachment } from 'app/core/models/attachment';
import { Lookup } from 'app/core/models/lookup';
import { ModuleDrawing } from 'app/core/models/module-drawing';
import { FileService } from 'app/core/services/file.service';
import { ModuleService } from 'app/core/services/modules.service';
import { UploadAttachmentDataService } from 'app/core/services/upload-attachment-data.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors';
import { items } from 'app/mock-api/apps/file-manager/data';
import { Subject, filter, takeUntil } from 'rxjs';
interface CustomFile extends File {
  dataUrl?: string;
}
@Component({
  selector: 'app-upload-attachments',
  templateUrl: './upload-attachments.component.html',
  styleUrls: ['./upload-attachments.component.scss'],
})
export class UploadAttachmentsComponent implements OnInit {
  @ViewChild('upload') uploadComponent: UploadComponent;
  @Input() public canUpload: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public uploadSaveUrl: string = '';
  @Input() public isPdfOnly: boolean = false;
  @Input() public item: ITrackerView = {} as ITrackerView;
  @Input() public attachments: any = [];
  @Output() updatedAttachments: EventEmitter<any[]> = new EventEmitter<any[]>();
  public selectedProject: Lookup;
  public uploadedAttachments: Attachment[] = [];
  selectedFiles: CustomFile[] = [];

  // @Output() public attachmentUrls: any = [];

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private moduleSvc: ModuleService,
    private store: Store<AppState>,
    private _uploadAttachmentService: UploadAttachmentDataService,
    private matSnackbar: MatSnackBar,
    private fileSvc: FileService,
    private _fuseDrawerService: FuseDrawerService,
  ) {
    this._uploadAttachmentService.data
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(x => !!x),
      )
      .subscribe(x => {
        this.selectedFiles = [];
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectProject)
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(project => !!project),
      )
      .subscribe(project => {
        this.selectedProject = project;
      });
    // this.uploadedAttachments = [];
  }

  public deleteDrawing(drawing: ModuleDrawing) {
    this.moduleSvc
      .deleteDrawing(this.selectedProject.id, drawing.moduleId, drawing.id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        const idx = this.attachments.findIndex(x => x.id === drawing.id);
        this.attachments.splice(idx, 1);
      });
  }
  // public downloadDrawing(downloadFile) {
  //   window.open(downloadFile.url, '_blank');
  // }

  public formatFileSize(bytes: number): string {
    if (bytes === 0) {
      return '0 B';
    }

    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  public successEventHandler(ev: SuccessEvent): void {
    if (this.item.type === 'attachments') {
      if (ev.operation === 'upload') {
        const attachment: Attachment = {
          name: ev.files[0].name,
          fileUri: ev.response.body.fileUri,
        };
        this.attachments.push(attachment);
        // this.uploadedAttachments.push(attachment);
        this.uploadComponent.removeFilesByUid(ev.files[0].uid);
      }
    }
    if (this.item.type === 'drawings') {
      if (ev.operation === 'upload') {
        const drawing: ModuleDrawing = ev.response.body;
        const urlParts = drawing.url.split('.');
        const fileExtension = urlParts[urlParts.length - 1];
        drawing.name = `${drawing.name}.${fileExtension}`;

        this.attachments.push(drawing);
        this.uploadComponent.removeFilesByUid(ev.files[0].uid);
      }
    }

    // this.attachmentUrls.emit(this.attachments);
  }

  public async uploadEventHandler(ev: UploadEvent) {
    if (ev.files.length === 0) {
      return;
    }

    switch (this.item.type) {
      case 'drawings':
        this.handleDrawingsUpload(ev);
        break;

      case 'attachments':
        this.handleAttachmentsUpload(ev);
        break;
    }
  }

  private handleDrawingsUpload(ev: UploadEvent): void {
    ev.data = {
      moduleId: this.item.id,
      projectId: this.item.projectId,
    };
  }

  private handleAttachmentsUpload(ev: UploadEvent): void {
    ev.data = {
      fileName: ev.files[0].name,
      file: ev.files[0].rawFile,
    };
  }

  selectEventHandler(event: any): void {
    if (!this.isPdfOnly) {
      return;
    }
    this.downloadPDFOnly(event);
  }

  downloadPDFOnly(event: any) {
    const files = event.files;
    const pdfFiles = files.filter(file => this.isPDF(file));
    if (pdfFiles.length !== files.length) {
      this.matSnackbar.open('Please select only PDF files.', 'Info');
      event.preventDefault();
      return;
    }
    event.files = pdfFiles;
  }

  isPDF(file: any): boolean {
    const allowedExtensions = ['pdf'];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf('.') + 1)
      .toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }
  filerImages(items) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];

    const imageAttachments = items.filter(attachment => {
      let urlParts = [];
      if (attachment.fileUri) {
        urlParts = attachment.fileUri.split('.');
      } else if (attachment.url) {
        urlParts = attachment.url.split('.');
      }
      const fileExtension =
        urlParts.length > 1 ? `.${urlParts.pop()?.toLowerCase()}` : '';
      return imageExtensions.includes(fileExtension);
    });
    return imageAttachments;
  }

  filterPDFs(items) {
    const imageExtensions = ['.pdf'];

    const imageAttachments = items.filter(attachment => {
      let urlParts = [];
      if (attachment.fileUri) {
        urlParts = attachment.fileUri.split('.');
      } else if (attachment.url) {
        urlParts = attachment.url.split('.');
      }
      const fileExtension =
        urlParts.length > 1 ? `.${urlParts.pop()?.toLowerCase()}` : '';
      return imageExtensions.includes(fileExtension);
    });
    return imageAttachments;
  }
  handleUpdatedAttachments(updatedAttachments: any[]) {
    if (this.item.type === 'drawings') {
      this.attachments = updatedAttachments;
    } else if (this.item.type === 'attachments') {
      this.attachments = updatedAttachments;
      // this.uploadedAttachments = updatedAttachments;
      this.updatedAttachments.emit(this.attachments);
    }
  }

  // onFileSelected(event: any): void {
  //   const files = event.target.files as FileList;

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];

  //     // Check file type
  //     if (this.isValidFileType(file)) {
  //       const reader = new FileReader();

  //       reader.onload = (e: any) => {
  //         const customFile: CustomFile = file as CustomFile;
  //         customFile.dataUrl = e.target.result;
  //         this.selectedFiles.push(customFile);
  //       };

  //       reader.readAsDataURL(file);
  //     } else {
  //       this.matSnackbar.open(
  //         `File '${file.name}' is not a valid PDF or image file and will be ignored.`,
  //         'Info',
  //       );
  //     }
  //   }
  // }
  onFileSelected(event: any): void {
    const files = Array.from(event.target.files) as File[];

    for (const file of files) {
      // Check file type
      if (this.isValidFileType(file)) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const customFile: CustomFile = file as CustomFile;
          customFile.dataUrl = e.target.result;
          this.selectedFiles.push(customFile);
        };

        reader.readAsDataURL(file);
      } else {
        this.matSnackbar.open(
          `File '${file.name}' is not a valid PDF or image file and will be ignored.`,
          'Info',
        );
      }
    }
  }

  isValidFileType(file: File): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    return allowedTypes.includes(file.type);
  }

  // async handleFileChange(event: any): Promise<void> {
  //   const fileInput = event.target as HTMLInputElement;
  //   const files = Array.from(fileInput.files);

  //   if (files.length === 0) {
  //     return;
  //   }

  //   // Assuming you want to append the new files to the existing selectedFiles array
  //   this.selectedFiles.push(...(files as CustomFile[]));

  //   this.readAndDisplayFiles();

  //   this.matSnackbar.open(`${files.length} files added`, 'Info');
  // }
  async handleFileChange(event: any): Promise<void> {
    const fileInput = event.target as HTMLInputElement;
    const files = Array.from(fileInput.files);

    if (files.length === 0) {
      return;
    }

    // Filter out files that are not PDFs or images
    const allowedFileTypes = [
      'application/pdf',
      'image/jpeg',
      'image/jpg',
      'image/png',
    ];
    const filteredFiles = files.filter(file =>
      allowedFileTypes.includes(file.type),
    );

    if (filteredFiles.length === 0) {
      this.matSnackbar.open(`Upload only PDF and Image files.`, 'Info');
      return;
    }

    this.selectedFiles.push(...(filteredFiles as CustomFile[]));
    this.readAndDisplayFiles();
    this.matSnackbar.open(`${filteredFiles.length} files added`, 'Info');
    fileInput.value = '';
  }

  readAndDisplayFiles(): void {
    for (const file of this.selectedFiles) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        file.dataUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  async onUpload(): Promise<void> {
    if (this.selectedFiles.length === 0) {
      this.matSnackbar.open('No files to upload', 'Warning');
      return;
    }

    const uploadPromises = this.selectedFiles.map(async file => {
      const name = file.name;

      try {
        await this.uploadFile(file, name);
        this.matSnackbar.open(`Uploaded ${name} successfully`, 'Success');
      } catch (error) {
        this.matSnackbar.open(`Failed to upload ${name}`, 'Error');
      }
    });

    try {
      await Promise.all(uploadPromises);
      this.updatedAttachments.emit(this.attachments);
    } catch (error) {
      this.matSnackbar.open(`Failed to upload`, 'Error');
    } finally {
      // Assuming you want to clear the selectedFiles array after upload
      this.selectedFiles = [];
      const drawer = this._fuseDrawerService.getComponent(
        'uploadDrawingDrawer',
      );
      drawer.toggle();
    }
  }
  async uploadFile(file: File, name: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.fileSvc.uploadAttachments(file, name).subscribe(
        response => {
          const fileUri = response?.fileUri;
          if (fileUri) {
            this.attachments.push({
              name,
              fileUri,
            });
          }
          resolve();
        },
        error => {
          reject(error);
        },
      );
    });
  }
  getPdfFiles(files: CustomFile[]): CustomFile[] {
    const pdfFiles = files.filter((file: CustomFile) => {
      // Check if the file is a PDF based on its type or extension
      const isPdf =
        file.type === 'application/pdf' ||
        file.name.toLowerCase().endsWith('.pdf');

      return isPdf;
    });

    return pdfFiles;
  }
  getImageFiles(files: CustomFile[]): CustomFile[] {
    const imageFiles = files.filter((file: CustomFile) => {
      // Check if the file is an image based on its type or extension
      const isImage =
        file.type.startsWith('image/') ||
        (file.type === '' && /\.(jpg|jpeg|png|gif)$/i.test(file.name));

      return isImage;
    });

    return imageFiles;
  }
  deleteAttachment(attachment: CustomFile, index: number) {
    const indexToDelete = this.selectedFiles.findIndex((file: CustomFile) => {
      return (
        file.dataUrl === attachment.dataUrl && file.name === attachment.name
      );
    });
    if (indexToDelete !== -1) {
      this.selectedFiles.splice(indexToDelete, 1);
    }
  }
}
