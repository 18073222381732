/* eslint-disable */
export const items = [
  {
    id: 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
    name: 'Personal',
    createdBy: 'Brian Hughes',
    createdAt: 'April 24, 2018',
    modifiedAt: 'April 24, 2018',
    size: '87 MB',
    type: 'folder',
    contents: '57 files',
    description:
      'Personal documents such as insurance policies, tax papers and etc.',
  },
  {
    id: '6da8747f-b474-4c9a-9eba-5ef212285500',
    name: 'Photos',
    createdBy: 'Brian Hughes',
    createdAt: 'November 01, 2021',
    modifiedAt: 'November 01, 2021',
    size: '3015 MB',
    type: 'folder',
    contents: '907 files',
    description: 'Personal photos; selfies, family, vacation and etc.',
  },
  {
    id: 'ed58add1-45a7-41db-887d-3ca7ee7f2719',
    name: 'Work',
    createdBy: 'Brian Hughes',
    createdAt: 'May 8, 2020',
    modifiedAt: 'May 8, 2020',
    size: '14 MB',
    type: 'folder',
    contents: '24 files',
    description: 'Work related files, mainly documents and paychecks.',
  },
  {
    id: '5cb66e32-d1ac-4b9a-8c34-5991ce25add2',
    name: 'Contract #123',
    createdBy: 'Brian Hughes',
    createdAt: 'January 14, 2021',
    modifiedAt: 'January 14, 2021',
    size: '1.2 MB',
    type: 'PDF',
    contents: null,
    description: null,
  },
  {
    id: '3ffc3d84-8f2d-4929-903a-ef6fc21657a7',
    name: 'Estimated budget',
    createdBy: 'Brian Hughes',
    createdAt: 'December 14, 2020',
    modifiedAt: 'December 14, 2020',
    size: '679 KB',
    type: 'XLS',
    contents: null,
    description: null,
  },
  {
    id: '157adb9a-14f8-4559-ac93-8be893c9f80a',
    name: 'DMCA notice #42',
    createdBy: 'Brian Hughes',
    createdAt: 'May 8, 2021',
    modifiedAt: 'May 8, 2021',
    size: '1.5 MB',
    type: 'DOC',
    contents: null,
    description: null,
  },
  {
    id: '4f64597a-df7e-461c-ad60-f33e5f7e0747',
    name: 'Invoices',
    createdBy: 'Brian Hughes',
    createdAt: 'January 12, 2020',
    modifiedAt: 'January 12, 2020',
    size: '17.8 MB',
    type: 'PDF',
    contents: null,
    description: null,
  },
  {
    id: 'e445c445-57b2-4476-8c62-b068e3774b8e',
    name: 'Crash logs',
    createdBy: 'Brian Hughes',
    createdAt: 'June 8, 2020',
    modifiedAt: 'June 8, 2020',
    size: '11.3 MB',
    type: 'TXT',
    contents: null,
    description: null,
  },
  {
    id: 'b482f93e-7847-4614-ad48-b78b78309f81',
    name: 'System logs',
    createdBy: 'Brian Hughes',
    createdAt: 'June 8, 2020',
    modifiedAt: 'June 8, 2020',
    size: '9.3 MB',
    type: 'TXT',
    contents: null,
    description: null,
  },
  {
    id: 'ec07a98d-2e5b-422c-a9b2-b5d1c0e263f5',
    name: 'Personal projects',
    createdBy: 'Brian Hughes',
    createdAt: 'March 18, 2020',
    modifiedAt: 'March 18, 2020',
    size: '4.3 MB',
    type: 'DOC',
    contents: null,
    description: null,
  },
  {
    id: 'ae908d59-07da-4dd8-aba0-124e50289295',
    name: 'Biometric portrait',
    createdBy: 'Brian Hughes',
    createdAt: 'August 29, 2020',
    modifiedAt: 'August 29, 2020',
    size: '4.5 MB',
    type: 'JPG',
    contents: null,
    description: null,
  },
  {
    id: '4038a5b6-5b1a-432d-907c-e037aeb817a8',
    name: 'Scanned image 20201012-1',
    createdBy: 'Brian Hughes',
    createdAt: 'September 13, 2020',
    modifiedAt: 'September 13, 2020',
    size: '7.8 MB',
    type: 'JPG',
    contents: null,
    description: null,
  },
  {
    id: '630d2e9a-d110-47a0-ac03-256073a0f56d',
    name: 'Scanned image 20201012-2',
    createdBy: 'Brian Hughes',
    createdAt: 'September 14, 2020',
    modifiedAt: 'September 14, 2020',
    size: '7.4 MB',
    type: 'JPG',
    contents: null,
    description: null,
  },
  {
    id: '1417d5ed-b616-4cff-bfab-286677b69d79',
    name: 'Prices',
    createdBy: 'Brian Hughes',
    createdAt: 'April 07, 2020',
    modifiedAt: 'April 07, 2020',
    size: '2.6 MB',
    type: 'DOC',
    contents: null,
    description: null,
  },
  {
    id: 'bd2817c7-6751-40dc-b252-b6b5634c0689',
    name: 'Shopping list',
    createdBy: 'Brian Hughes',
    createdAt: 'March 26, 2021',
    modifiedAt: 'March 26, 2021',
    size: '2.1 MB',
    type: 'DOC',
    contents: null,
    description: null,
  },
  {
    id: '14fb47c9-6eeb-4070-919c-07c8133285d1',
    name: 'Summer budget',
    createdBy: 'Brian Hughes',
    createdAt: 'June 02, 2020',
    modifiedAt: 'June 02, 2020',
    size: '943 KB',
    type: 'XLS',
    contents: null,
    description: null,
  },
];
