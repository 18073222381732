import { Injectable } from '@angular/core';
import { FuseDrawerService } from '@fuse/components/drawer';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsDrawerDataService {
  private dataSource = new BehaviorSubject(null);

  data = this.dataSource.asObservable();

  constructor(private _fuseDrawerService: FuseDrawerService) {}

  changeData(data: SettingDrawer): void {
    this.dataSource.next(data);
  }
}

export interface SettingDrawer {
  drawerType: string;
  data: any;
}
