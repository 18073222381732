import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { UploadDrawingsComponent } from 'app/components/modules/upload-drawings/upload-drawings.component';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { ProjectStarterModule } from 'app/layout/common/project-starter/project-starter.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserMenuModule } from 'app/layout/common/user-menu/user-menu.module';
import { ModernLayoutComponent } from 'app/layout/layouts/horizontal/modern/modern.component';
import { SharedModule } from 'app/shared/shared.module';
import { SharedModule as SharedComponentsModule } from '../../../../components/shared/shared.module';
import { AddProductionLineComponent } from 'app/components/settings/add-production-line/add-production-line.component';
import { AddStationComponent } from 'app/components/settings/add-station/add-station.component';

@NgModule({
  declarations: [
    ModernLayoutComponent,
    UploadDrawingsComponent,
    AddProductionLineComponent,
    AddStationComponent,
  ],
  imports: [
    FuseDrawerModule,
    FuseFullscreenModule,
    FuseNavigationModule,
    HttpClientModule,
    LanguagesModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MessagesModule,
    NotificationsModule,
    ProjectStarterModule,
    RouterModule,
    SearchModule,
    SettingsModule,
    SharedComponentsModule,
    SharedModule,
    ShortcutsModule,
    UserMenuModule,
    UploadsModule,
  ],
  exports: [ModernLayoutComponent],
})
export class ModernLayoutModule {}
