import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseDrawerService } from '@fuse/components/drawer';
import { Store } from '@ngrx/store';
import { Lookup } from 'app/core/models/lookup';
import { Station } from 'app/core/models/station';
import { SettingsDrawerDataService } from 'app/core/services/setting-drawer-data.service';
import { StationsService } from 'app/core/services/stations.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors';
import { Subject, combineLatest, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-station',
  templateUrl: './add-station.component.html',
  styleUrls: ['./add-station.component.scss'],
})
export class AddStationComponent implements OnInit {
  buttonText: string = '';
  selectedProject: Lookup = null;
  station: Station = {
    id: '',
    name: '',
    sequence: 1,
    checklist: [],
    projectId: '',
    isEnabled: true,
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fuseDrawerSvc: FuseDrawerService,
    private _matSnackbar: MatSnackBar,
    private _settingDrawerSvc: SettingsDrawerDataService,
    private _stationsSvc: StationsService,
    private _store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    combineLatest({
      project: this._store.select(selectProject).pipe(
        takeUntil(this._unsubscribeAll),
        filter(project => !!project),
      ),
      drawerData: this._settingDrawerSvc.data.pipe(
        takeUntil(this._unsubscribeAll),
        filter(x => !!x && !!x.drawerType && x.drawerType === 'stations'),
      ),
    }).subscribe(({ project, drawerData }) => {
      this.selectedProject = project;
      this.station.projectId = project.id;

      if (drawerData.data) {
        this.station = drawerData.data;
      }
    });
  }

  addQuestion() {
    this.station.checklist.push({
      id: '',
      name: '',
      isEnabled: true,
      items: null,
    });
  }

  addStation(station: Station) {
    this._stationsSvc.addStation(station).subscribe(() => {
      this._matSnackbar.open('Station is added successfully.', 'Success');

      const drawer = this._fuseDrawerSvc.getComponent('globalSettings');
      drawer.toggle();
    });
  }

  removeQuestion(index: number) {
    this.station.checklist.splice(index, 1);
  }

  submitForm() {
    if (!this.station.id) {
      this.addStation(this.station);
    } else {
      this.updateStation(this.station);
    }
  }

  updateStation(station: Station) {
    this._stationsSvc.updateStation(station).subscribe(() => {
      this._matSnackbar.open('Station is updated successfully.', 'Success');

      const drawer = this._fuseDrawerSvc.getComponent('globalSettings');
      drawer.toggle();
    });
  }
}
