import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';

import { Lookup } from 'app/core/models/lookup';
import {
  PostProductionLine,
  ProductionLine,
} from 'app/core/models/production-line';
import { ProductionLinesService } from 'app/core/services/production-lines.service';
import { SettingsDrawerDataService } from 'app/core/services/setting-drawer-data.service';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors';
import { FuseDrawerService } from '@fuse/components/drawer';

@Component({
  selector: 'add-production-line',
  templateUrl: './add-production-line.component.html',
  styleUrls: ['./add-production-line.component.scss'],
})
export class AddProductionLineComponent implements OnInit, OnDestroy {
  data: ProductionLine = null;
  public selectedProject: Lookup;
  newProductionLine: string = '';
  isEnabled: boolean = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fuseDrawerService: FuseDrawerService,
    private _prodLineSvc: ProductionLinesService,
    private store: Store<AppState>,
    private matSnackbar: MatSnackBar,
    private _settingDrawerSvc: SettingsDrawerDataService,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectProject)
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(project => !!project),
      )
      .subscribe(project => {
        this.selectedProject = project;
      });

    this._settingDrawerSvc.data
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(
          x => !!x && !!x.drawerType && x.drawerType === 'production-lines',
        ),
      )
      .subscribe(x => {
        this.data = x.data;
        this.newProductionLine = x.data?.name;
        this.isEnabled = x.data?.isEnabled;
      });
  }

  addOrEditProductionLine() {
    const isNotBlank = this.newProductionLine.trim() !== '';

    if (isNotBlank) {
      const prodLine = {
        name: this.newProductionLine,
        projectId: this.selectedProject.id,
        isEnabled: this.isEnabled,
      };

      if (!this.data) {
        this.addProductionLine(prodLine);
      } else {
        const updatedProdLine = {
          id: this.data.id,
          ...prodLine,
        };

        this.updateProductionLine(updatedProdLine);
      }
    }
  }

  addProductionLine(productionLine: PostProductionLine) {
    this._prodLineSvc.addProductionLine(productionLine).subscribe(() => {
      this.matSnackbar.open(
        'Production line is added successfully.',
        'Success',
      );

      const drawer = this._fuseDrawerService.getComponent('globalSettings');
      drawer.toggle();
    });
  }

  updateProductionLine(productionLine: ProductionLine) {
    this._prodLineSvc
      .updateProductionLine(this.selectedProject.id, productionLine)
      .subscribe(() => {
        this.matSnackbar.open(
          'Production line is updated successfully.',
          'Success',
        );

        const drawer = this._fuseDrawerService.getComponent('globalSettings');
        drawer.toggle();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
