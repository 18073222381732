import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import {
  DropDownListModule,
  DropDownsModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DirectivesModule } from 'app/core/directives/directives.module';

import { JsonObjectInputComponent } from './json-object-input/json-object-input.component';
import { LeftVerticalNavigationComponent } from './left-vertical-navigation/left-vertical-navigation.component';
import { PageHeadingComponent } from './page-heading/page-heading.component';
import { TimelineComponent } from './timeline/timeline.component';
import { HistoryComponent } from './history/history.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { AttachmentModalComponent } from './attachment-modal/attachment-modal.component';
import { UploadModule } from '@progress/kendo-angular-upload';

@NgModule({
  declarations: [
    PageHeadingComponent,
    LeftVerticalNavigationComponent,
    JsonObjectInputComponent,
    TimelineComponent,
    HistoryComponent,
    BottomSheetComponent,
    ConfirmationModalComponent,
    AttachmentModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatDividerModule,
    MatMenuModule,
    MatDialogModule,
    MatListModule,
    MatCheckboxModule,
    MatRadioModule,
    // FuseModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatTableModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatCardModule,
    // Kendo Modules
    GridModule,
    ButtonsModule,
    DropDownsModule,
    DropDownListModule,
    InputsModule,
    LabelModule,
    ExcelModule,
    PopupModule,
    MultiSelectModule,

    // Custom Directives
    DirectivesModule,

    FuseNavigationModule,
    UploadModule,
  ],
  exports: [
    PageHeadingComponent,
    DirectivesModule,
    LeftVerticalNavigationComponent,
    JsonObjectInputComponent,
    LabelModule,

    // Kendo Modules
    GridModule,
    ButtonsModule,
    DropDownsModule,
    InputsModule,
    LabelModule,
    ExcelModule,
    PopupModule,
    DropDownListModule,
    MultiSelectModule,

    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TimelineComponent,
    HistoryComponent,
    UploadModule,
  ],
})
export class SharedModule {}
