import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';

import { environment } from 'environments/environment';

import { Station, StationChecklistSection } from '../models/station';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class StationsService extends BaseService<Station> {
  private stationUpdatedSub = new Subject();

  stationUpdated$ = this.stationUpdatedSub.asObservable();

  constructor(protected http: HttpClient) {
    super(http, 'stations');
  }

  protected get apiBaseUrl(): string {
    return `${environment.apiBaseUrl}`;
  }

  addStation(station: Station) {
    return this.http
      .post(`${this.apiBaseUrl}/stations`, station)
      .pipe(tap(resp => this.stationUpdatedSub.next(resp)));
  }

  getByProject(
    projectId: string,
    includeDisabled = false,
  ): Observable<Station[]> {
    return this.http.get<Station[]>(
      `${this.apiBaseUrl}/stations/by-project/${projectId}?includeDisabled=${includeDisabled}`,
    );
  }

  updateStation(station: Station) {
    return this.http
      .put(
        `${this.apiBaseUrl}/stations/${station.id}?partitionKey=${station.projectId}`,
        station,
      )
      .pipe(tap(resp => this.stationUpdatedSub.next(resp)));
  }

  swap(sourceId: string, destinationId: string, projectId: string) {
    const url = `${environment.apiBaseUrl}/stations/swap/${sourceId}/${destinationId}?projectId=${projectId}`;

    return this.http
      .post(url, null)
      .pipe(tap(() => this.stationUpdatedSub.next(null)));
  }

  enableStation(projectId: string, stationId: string) {
    return this.http.get(
      `${this.apiBaseUrl}/stations/${projectId}/${stationId}/enable`,
    );
  }

  disableStation(projectId: string, stationId: string) {
    return this.http.get(
      `${this.apiBaseUrl}/stations/${projectId}/${stationId}/disable`,
    );
  }

  enableChecklistItems(projectId: string, stationId: string, ids: string[]) {
    return this.http.post(
      `${this.apiBaseUrl}/stations/${projectId}/${stationId}/enable-checklist`,
      {
        ids,
      },
    );
  }

  disableChecklistItems(projectId: string, stationId: string, ids: string[]) {
    return this.http.post(
      `${this.apiBaseUrl}/stations/${projectId}/${stationId}/disable-checklist`,
      {
        ids,
      },
    );
  }

  updateChecklist(
    projectId: string,
    stationId: string,
    items: StationChecklistSection[],
  ) {
    return this.http.post(
      `${this.apiBaseUrl}/stations/${projectId}/${stationId}/update-checklist`,
      items,
    );
  }

  uploadItems(
    projectId: string,
    stationId: string,
    data: string[],
  ): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}/stations/bulk-upload`, {
      projectId,
      stationId,
      data,
    });
  }

  deleteStation(projectId: string, statinId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${projectId}/${statinId}`);
  }
}
