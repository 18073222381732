import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/fuse-core/config/app.config';
import { CoreModule } from 'app/fuse-core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';

import { ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/guards/auth.guard';
import { CommonInterceptor } from './core/interceptors/common.interceptor';
import { SpinnerInterceptor } from './core/interceptors/spinner.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import * as fromApp from './core/store/reducers/app.reducer';
import { LayoutComponent } from './layout/layout.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import {
  loginRequest,
  msalConfig,
  protectedResources,
} from './modules/auth/auth-config';
import { AdminSettingComponent } from './components/admin-setting/admin-setting.component';

const routes: Routes = [
  {
    path: 'admin-settings',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/admin-setting/admin-setting.module').then(
        x => x.AdminSettingModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'project-admin-settings',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/admin-setting/admin-setting.module').then(
        x => x.AdminSettingModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/home/home.module').then(x => x.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'empty',
    },
    loadChildren: () =>
      import('./components/auth/auth.module').then(x => x.AuthModule),
  },
  {
    path: 'inspections',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/tracker/tracker.module').then(x => x.TrackerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/settings/settings.module').then(
        x => x.SettingsModule,
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'qr-codes',
  //   component: LayoutComponent,
  //   resolve: {
  //     initialData: InitialDataResolver,
  //   },
  //   data: {
  //     layout: 'modern',
  //   },
  //   loadChildren: () =>
  //     import('./components/qr-codes-generator/qr-codes-generator.module').then(
  //       x => x.QrCodesGeneratorModule,
  //     ),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'create-inspection',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/create-inspection/create-inspection.module').then(
        x => x.CreateInspectionModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-inspection',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/view-inspection/view-inspection.module').then(
        x => x.ViewInspectionModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'scan',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/scan/scan.module').then(x => x.ScanModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'modules',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'modern',
    },
    loadChildren: () =>
      import('./components/modules/modules.module').then(
        x => x.QrCodesGeneratorModule,
      ),
    canActivate: [AuthGuard],
  },
  // {
  //     path: "",
  //     component: LayoutComponent,
  //     resolve: {
  //         initialData: InitialDataResolver,
  //     },
  //     data: {
  //         layout: "modern",
  //     },
  //     loadChildren: () =>
  //         import("./components/requests/requests.module").then(
  //             (x) => x.RequestsModule
  //         ),
  //     canActivate: [AuthGuard],
  // },
  {
    path: '**',
    redirectTo: 'home',
  },
];

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: PreloadAllModules,
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    protectedResources.claddingAPI.endpoint,
    protectedResources.claddingAPI.scopes,
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

@NgModule({
  declarations: [AppComponent, LoadingSpinnerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, routerConfig),

    TranslateModule.forRoot(),
    ReactiveFormsModule,

    // Fuse & Fuse Mock API
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core
    CoreModule,

    // Layout
    LayoutModule,

    // Material
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatExpansionModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,

    // Toastr
    ToastrModule.forRoot(),

    // 3rd party modules
    MarkdownModule.forRoot({}),

    // Store Modules
    StoreModule.forRoot(fromApp.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    FontAwesomeModule,
    MsalModule,
    ChartsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonInterceptor,
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 10000 },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true },
    },
    DatePipe,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  // constructor(library: FaIconLibrary) {
  //     library.addIconPacks(fas, far);
  // }

  constructor() {}
}
