import { ActionReducerMap } from '@ngrx/store';

import * as fromAuth from './auth.reducer';
import * as fromCommon from './common.reducer';

export interface AppState {
  auth: fromAuth.IState;
  common: fromCommon.IState;
}

export const reducer: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  common: fromCommon.reducer,
};
