import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { PageHeadingComponent } from './page-heading/page-heading.component';
import { UploadAttachmentsComponent } from './upload-attachments/upload-attachments.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { PdfViewComponent } from './pdf-view/pdf-view.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { ImageSwiperComponent } from 'app/components/tracker/image-swiper/image-swiper.component';
import { LightgalleryModule } from 'lightgallery/angular';

@NgModule({
  declarations: [
    PageHeadingComponent,
    UploadAttachmentsComponent,
    PdfViewComponent,
    ImageViewerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    UploadModule,
    LightgalleryModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageHeadingComponent,
    UploadAttachmentsComponent,
  ],
})
export class SharedModule {}
