import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerInterceptor implements HttpInterceptor {
  reqCount = 0;
  private exceptions: string[] = [
    '/sub-folders',
    '/notifications',
    '/auth/login',
    '/auth/refresh',
    '/auth/token',
    '/auth/sso',
    '/config',
    'assets/icons',
  ];
  private requests: HttpRequest<any>[] = [];

  constructor(private spinnerSvc: SpinnerService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const exceptionalRequest = this.exceptions.find(
      x => req.url.indexOf(x) !== -1,
    );

    if (exceptionalRequest) {
      return next.handle(req);
    }

    this.spinnerSvc.start(req.url);
    return next.handle(req).pipe(
      finalize(() => {
        this.spinnerSvc.stop(req.url);
      }),
    );
  }
}
