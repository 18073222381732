import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { InitialData } from 'app/app.types';

import { LOCAL_STORAGE_KEYS } from './core/constants/local-storage-keys';
import { AuthService } from './core/services/auth.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { ScreenSizeService } from './core/services/screen-size.service';
import { ADMIN, PROJECT_ADMIN } from './utils/constants';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  static mobileLinks = ['home', 'create-inspection'];

  constructor(
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _localStorageSvc: LocalStorageService,
    private _screenSizeSvc: ScreenSizeService,
  ) {}

  resolve(): Observable<InitialData> {
    if (this._localStorageSvc.get(LOCAL_STORAGE_KEYS.AuthToken)) {
      if (this._authService.token && this._authService.tokenExpired) {
        return this._authService.refreshAuthToken().pipe(
          switchMap(() => {
            return this.allSubscriptions();
          }),
        );
      }

      return this.allSubscriptions();
    } else {
      return forkJoin([
        this._httpClient.get<any>('api/common/messages'),
        this._httpClient.get<any>('api/common/navigation'),
        this._httpClient.get<any>('api/common/navigation'),
        this._httpClient.get<any>('api/common/notifications'),
        this._httpClient.get<any>('api/common/shortcuts'),
        this._httpClient.get<any>('api/common/user'),
      ]).pipe(
        map(([messages, navigation, notifications, shortcuts, user]) => {
          return {
            messages,
            navigation: {
              compact: navigation.compact,
              default: navigation.default,
              futuristic: navigation.futuristic,
              horizontal: navigation.horizontal,
            },
            notifications,
            shortcuts,
            user,
          };
        }),
      );
    }
  }

  private allSubscriptions() {
    return forkJoin([
      this._httpClient.get<any>('api/common/messages'),
      this._httpClient.get<any>('api/common/navigation'),
      this._httpClient.get<any>('api/common/notifications'),
      this._httpClient.get<any>('api/common/shortcuts'),
      this._httpClient.get<any>('api/common/user'),
    ]).pipe(
      map(([messages, navigation, notifications, shortcuts, user]) => {
        if (this._screenSizeSvc.screenSize === 'small') {
          navigation.compact = filterMobileNavigation(navigation.compact);
          navigation.default = filterMobileNavigation(navigation.default);
          navigation.futuristic = filterMobileNavigation(navigation.futuristic);
          navigation.horizontal = filterMobileNavigation(navigation.horizontal);
        }

        if (
          this._authService.roleClaim !== ADMIN &&
          this._authService.roleClaim !== PROJECT_ADMIN
        ) {
          navigation.compact = navigation.compact.filter(
            x => x.id !== 'config',
          );
          navigation.default = navigation.default.filter(
            x => x.id !== 'config',
          );
          navigation.futuristic = navigation.futuristic.filter(
            x => x.id !== 'config',
          );
          navigation.horizontal = navigation.horizontal.filter(
            x => x.id !== 'config',
          );
        }

        if (this._authService.roleClaim !== ADMIN) {
          navigation.compact = navigation.compact.filter(
            x => x.id !== 'admin-settings',
          );
          navigation.default = navigation.default.filter(
            x => x.id !== 'admin-settings',
          );
          navigation.futuristic = navigation.futuristic.filter(
            x => x.id !== 'admin-settings',
          );
          navigation.horizontal = navigation.horizontal.filter(
            x => x.id !== 'admin-settings',
          );
        }
        if (this._authService.roleClaim !== PROJECT_ADMIN) {
          navigation.compact = navigation.compact.filter(
            x => x.id !== 'project-admin-settings',
          );
          navigation.default = navigation.default.filter(
            x => x.id !== 'project-admin-settings',
          );
          navigation.futuristic = navigation.futuristic.filter(
            x => x.id !== 'project-admin-settings',
          );
          navigation.horizontal = navigation.horizontal.filter(
            x => x.id !== 'project-admin-settings',
          );
        }

        return {
          messages,
          navigation: {
            compact: navigation.compact,
            default: navigation.default,
            futuristic: navigation.futuristic,
            horizontal: navigation.horizontal,
          },
          notifications,
          shortcuts,
          user,
        };
      }),
    );

    function filterMobileNavigation(links: any[]) {
      return links.filter((x: any) =>
        InitialDataResolver.mobileLinks.includes(x.id),
      );
    }
  }
}
