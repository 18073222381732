import { User } from 'app/core/models/auth/user';
import * as actions from '../actions/auth.actions';

export interface IState {
  isLoadingUser: boolean;
  loggedInAccount: any;
  loginErrorMessage: string;
  loginResult: boolean;
  user: User;
}

const initialState: IState = {
  isLoadingUser: false,
  loggedInAccount: null,
  loginErrorMessage: '',
  loginResult: true,
  user: null,
};

export const reducer = (
  state = initialState,
  action: actions.AuthActions,
): IState => {
  switch (action.type) {
    case actions.LOAD_USER:
      return {
        ...state,
        isLoadingUser: action.payload,
      };

    case actions.USER_LOGIN:
      return {
        ...state,
        loggedInAccount: action.payload,
      };

    case actions.SET_USER:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };

    case actions.SET_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        loginErrorMessage: action.payload,
      };

    case actions.SET_LOGIN_RESULT:
      return {
        ...state,
        loginResult: action.payload,
      };

    default:
      return state;
  }
};
