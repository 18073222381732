import { createSelector } from '@ngrx/store';

import { AppState } from '../reducers/app.reducer';
import * as fromCommon from '../reducers/common.reducer';

export const selectCommon = (state: AppState) => state.common;

export const selectClientConfig = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.clientConfig,
);

export const selectIsMobileView = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.isMobileView,
);

export const selectSpinner = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.inProgress,
);

export const showGridActions = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.showGridActions,
);

export const selectSubmissions = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.submissions,
);

export const selectBreadCrumbs = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.breadCrumbs,
);

export const selectProject = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.selectedProject,
);

export const selectMetadataFields = createSelector(
  selectCommon,
  (state: fromCommon.IState) => state.metadataFields,
);
