import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectSpinner } from 'app/core/store/selectors/common.selectors';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeHandler } from '../core/components/unsubscribe-handler';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent
  extends UnsubscribeHandler
  implements OnInit
{
  @Input() value = 100;
  @Input() diameter = 50;
  @Input() mode = 'indeterminate';
  @Input() strokeWidth = 5;
  @Input() color = 'accent';

  showLoader = false;

  constructor(private store: Store<AppState>) {
    super();

    this.subscribeSpinner();
  }

  ngOnInit(): void {}

  private subscribeSpinner(): void {
    this.store
      .select(selectSpinner)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(inProgress => {
        this.showLoader = inProgress;
      });
  }
}
