import { Action } from '@ngrx/store';
import { User } from 'app/core/models/auth/user';

// import { UserProject } from 'app/core/interfaces/user-project';
// import { User } from '../../interfaces/user';

export const LOAD_USER = 'auth/load-user';
export const USER_LOGIN = 'USER_LOGIN';
export const SET_LOGIN_ERROR_MESSAGE = 'auth/set-login-error-message';
export const SET_LOGIN_RESULT = 'auth/set-login-attempt';
export const SET_USER = 'auth/set-user';
export const SET_USER_PROJECTS = 'auth/set-user-projects';

export class LoadUserAction implements Action {
  readonly type = LOAD_USER;

  constructor(public payload: boolean) {}
}

export class UserLoginAction implements Action {
  readonly type = USER_LOGIN;

  constructor(public payload: any) {}
}

export class SetLoginErrorMessageAction implements Action {
  readonly type = SET_LOGIN_ERROR_MESSAGE;

  constructor(public payload: string) {}
}

export class SetLoginResultAction implements Action {
  readonly type = SET_LOGIN_RESULT;

  constructor(public payload: boolean) {}
}

export class SetUserAction implements Action {
  readonly type = SET_USER;

  constructor(public payload: User) {}
}

export type AuthActions =
  | LoadUserAction
  | SetLoginErrorMessageAction
  | SetLoginResultAction
  | SetUserAction
  | UserLoginAction;
