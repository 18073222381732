<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="header relative flex flex-0 items-center w-full sm:h-19 px-4 md:px-6 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <div class="flex items-center sm:mx-2 mr-1">
        <div class="hidden lg:flex cursor-pointer" routerLink="/home">
          <img
            class="dark:hidden w-24"
            src="assets/images/logos/linq-logo.png"
          />
          <img
            class="hidden dark:flex w-24"
            src="assets/images/logos/linq-logo.png"
          />
        </div>
        <img
          class="flex lg:hidden w-24"
          src="assets/images/logos/linq-logo.png"
        />
      </div>

      <!-- Project Dropdown -->
      <app-project-starter></app-project-starter>

      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation
        class="mr-2"
        [name]="'mainNavigation'"
        [navigation]="data.navigation.horizontal"
      >
      </fuse-horizontal-navigation>
    </ng-container>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto md:space-x-2">
      <fuse-fullscreen
        *ngIf="!isScreenSmall"
        style="display: flex"
      ></fuse-fullscreen>
      <!-- <alec-settings
        *ngIf="user?.role.toLowerCase() === 'admin'"
      ></alec-settings> -->
      <user-menu></user-menu>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-row flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
           Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center w-full h-10 sm:h-10 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="footer-text text-secondary"
      >ALEC &copy; {{ currentYear }}</span
    >
  </div>
</div>

<!-- Setting Drawer -->
<fuse-drawer
  class="w-100 min-w-100"
  fixed
  transparentOverlay
  [mode]="'over'"
  [name]="'settingsDrawer'"
  [position]="'right'"
  #settingsDrawer
  class="w-60 min-w-60 sm:w-120 sm:min-w-120"
>
  <div class="flex flex-col w-full overflow-auto bg-card">
    <div
      class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary"
    >
      <mat-icon
        class="icon-size-7 text-current"
        [svgIcon]="'heroicons_solid:cog'"
      ></mat-icon>
      <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div>
    </div>

    <div class="flex flex-col px-4 sm:px-6 py-6">
      <div class="text-md font-semibold text-secondary">ADMIN</div>
      <div class="flex flex-col sm:flex-row justify-items-start">
        <div
          class="flex items-center mr-2 mt-6 py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          (click)="navigateTo(item.link)"
          *ngFor="let item of drawerChipsOptions"
        >
          <div class="flex items-center rounded-full overflow-ellipsis">
            <mat-icon class="icon-size-5" [svgIcon]="item.icon"></mat-icon>
          </div>
          <div
            class="flex items-center ml-2 font-medium leading-5 whitespace-nowrap overflow-ellipsis overflow-hidden"
            style="font-size: 0.9375rem"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</fuse-drawer>

<!-- Upload Drawing Drawer -->
<fuse-drawer
  class="w-200 min-w-200 z-index"
  fixed
  transparentOverlay
  [mode]="'over'"
  [name]="'uploadDrawingDrawer'"
  [position]="'right'"
  #uploadDrawingDrawer
>
  <div class="flex flex-col w-full overflow-auto bg-card">
    <div
      class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary sticky top-0 right-0 left-0 z-[999]"
    >
      <mat-icon
        class="icon-size-7 text-current"
        [svgIcon]="'heroicons_outline:paper-clip'"
      ></mat-icon>
      <div class="ml-3 text-2xl font-semibold tracking-tight">
        {{ title }}
      </div>
      <!-- Add a close button here -->
      <button class="ml-auto" (click)="closeUploadDrawings()">
        <mat-icon
          class="icon-size-7 text-current"
          [svgIcon]="'heroicons_outline:x'"
        ></mat-icon>
      </button>
    </div>

    <div class="flex flex-col p-6">
      <div class="font-semibold text-md text-primary">{{ steelChasis }}</div>
      <div class="grid w-full grid-cols-1 gap-3 justify-items-start mt-6">
        <ng-container>
          <!-- <app-upload-drawings  ></app-upload-drawings> -->
          <app-upload-attachments
            [canUpload]="isAdmin()"
            [canDelete]="isAdmin()"
            [attachments]="drawings"
            [uploadSaveUrl]="uploadSaveUrl"
            [item]="item"
            [isPdfOnly]="isPdfOnly"
            (updatedAttachments)="handleUpdatedAttachments($event)"
          ></app-upload-attachments>
        </ng-container>
      </div>
    </div>
  </div>
</fuse-drawer>

<!-- Setting Global Drawer -->
<fuse-drawer
  class="w-100 min-w-100"
  fixed
  transparentOverlay
  [mode]="'over'"
  [name]="'globalSettings'"
  [position]="'right'"
  #uploadDrawingDrawer
>
  <div class="flex flex-col w-full overflow-auto bg-card">
    <div
      class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary"
    >
      <!-- <mat-icon class="icon-size-7 text-current" [svgIcon]="'heroicons_outline:paper-clip'"></mat-icon> -->
      <div class="ml-3 text-2xl font-semibold tracking-tight">
        <span>{{ getHeaderTitle(settingDrawerData) }}</span>
      </div>
    </div>

    <div class="flex flex-col p-6">
      <div class="font-semibold text-md text-primary">{{ steelChasis }}</div>
      <ng-container>
        <add-production-line
          *ngIf="
            settingDrawerData &&
            settingDrawerData.drawerType === 'production-lines'
          "
          class="w-full"
        ></add-production-line>
        <app-add-station
          *ngIf="
            settingDrawerData && settingDrawerData.drawerType === 'stations'
          "
          class="w-full"
        ></app-add-station>
      </ng-container>
    </div>
  </div>
</fuse-drawer>
