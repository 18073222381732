<div class="p-3">
    <div>
        <mat-form-field class="w-full">
            <mat-label>Station Name</mat-label>
            <input matInput [(ngModel)]="station.name" placeholder="Enter Station Name">
        </mat-form-field>

        <div class="add-button">
            <button mat-icon-button color="primary" class="mt-1" (click)="addQuestion()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <!-- Dynamic Questions -->
    <div *ngFor="let checklistItem of station.checklist; let i = index" class="mt-1">
        <div class="flex flex-row justify-between">
            <div class="text-lg mb-2">Item # {{ i + 1 }}</div>
            <div>
                <button mat-icon-button color="warn" (click)="removeQuestion(i)">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
        </div>
        <div class="border border-gray-300 rounded-lg p-3 shadow-md h-25">
            <!-- Use a textarea for multiline input -->
            <textarea class="editable-content w-full h-22" [(ngModel)]="checklistItem.name"
                placeholder="Enter item details" row="4"></textarea>
        </div>
    </div>

    <!-- Submit Button -->
    <div class="add-button">
        <button mat-raised-button color="primary" class="mt-6 w-40 text-lg" (click)="submitForm()">
            {{station.id ? 'Update' : 'Create'}}
        </button>
    </div>
</div>