<!-- Button -->
<button
  *ngIf="user?.role === 'admin' || user?.role === 'inspector'"
  mat-raised-button
  class="submit-inspection md:invisible"
  color="primary"
  (click)="navigateToInspection()"
>
  <mat-icon svgIcon="heroicons_outline:camera"></mat-icon>
  <!-- <span>Submit Inspection</span> -->
</button>
<button
  mat-icon-button
  [matTooltip]="tooltip || 'Toggle Fullscreen'"
  (click)="toggleFullscreen()"
>
  <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<!-- Default icon -->
<ng-template #defaultIconTpl>
  <mat-icon [svgIcon]="'heroicons_outline:arrows-expand'"></mat-icon>
</ng-template>
