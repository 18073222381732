<div class="production-line-container flex items-center space-x-4">
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Production Line Name</mat-label>
            <input matInput [(ngModel)]="newProductionLine" placeholder="Production Line Name">
        </mat-form-field>
    </div>
    <div class="w-full flex items-center space-x-7">
        <mat-label>Status</mat-label>
        <mat-slide-toggle  [(ngModel)]="isEnabled"  color="primary">
        </mat-slide-toggle>
    </div>
    <button mat-raised-button class="mt-2"  color="primary" (click)="addOrEditProductionLine()">{{data ? 'Update' : 'Create'}}</button>
</div>