/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

const navigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home',
  },
  {
    id: 'modules',
    title: 'Modules',
    type: 'basic',
    icon: 'heroicons_outline:qrcode',
    link: '/modules',
  },
  {
    id: 'tracker',
    title: 'Inspections',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-check',
    link: '/inspections',
  },
  // {
  //   id: 'create-inspection',
  //   title: 'Inspection',
  //   type: 'basic',
  //   icon: 'heroicons_outline:list-bullet',
  //   link: '/create-inspection',
  // },
  {
    id: 'config',
    title: 'Settings',
    type: 'basic',
    icon: 'heroicons_outline:cog',
    link: '/settings',
  },
  {
    id: 'admin-settings',
    title: 'Admin',
    type: 'basic',
    icon: 'settings_suggest',
    link: '/admin-settings',
  },
  {
    id: 'project-admin-settings',
    title: 'Project Admin',
    type: 'basic',
    icon: 'settings_suggest',
    link: '/project-admin-settings',
  },
];

export const defaultNavigation: FuseNavigationItem[] = [...navigation];

export const compactNavigation: FuseNavigationItem[] = [];

export const futuristicNavigation: FuseNavigationItem[] = [];

export const horizontalNavigation: FuseNavigationItem[] = [...navigation];
