import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Lookup } from 'app/core/models/lookup';
import { LookupsService } from 'app/core/services/lookups.service';
import { SetProject } from 'app/core/store/actions/common.actions';
import { AppState } from 'app/core/store/reducers/app.reducer';
import { selectProject } from 'app/core/store/selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-project-starter',
  templateUrl: './project-starter.component.html',
  styleUrls: ['./project-starter.component.scss'],
})
export class ProjectStarterComponent implements OnInit, OnDestroy {
  @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
  @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;

  public projects: {
    id: string;
    name: string;
    value: string;
    code: string;
    headingName: string;
    isHavingStage: boolean;
  }[] = [];
  public selectedProject: Lookup;
  public filteredTags: any[];
  public tagsEditMode: boolean = false;
  public searchText: string;
  projectFromQuerySet: boolean = false;
  private _tagsPanelOverlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private lookupSvc: LookupsService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.lookupSvc
      .getProjectLookup()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(projects => {
        this.projects = projects.map(project => ({
          ...project,
          code: project.value.split(' | ')[0],
        }));

        this.setDefaultProject();
      });

    this.store
      .select(selectProject)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedProject => {
        this.selectedProject = selectedProject;
        this.setDefaultProject();
      });
  }

  openTagsPanel(): void {
    // Create the overlay
    this._tagsPanelOverlayRef = this._overlay.create({
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
        .withFlexibleDimensions()
        .withViewportMargin(64)
        .withLockedPosition()
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
    });

    // Subscribe to the attachments observable
    this._tagsPanelOverlayRef.attachments().subscribe(() => {
      // Focus to the search input once the overlay has been attached
      this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
    });

    // Create a portal from the template
    const templatePortal = new TemplatePortal(
      this._tagsPanel,
      this._viewContainerRef,
    );

    // Attach the portal to the overlay
    this._tagsPanelOverlayRef.attach(templatePortal);

    // Subscribe to the backdrop click
    this._tagsPanelOverlayRef.backdropClick().subscribe(() => {
      // If overlay exists and attached...
      if (
        this._tagsPanelOverlayRef &&
        this._tagsPanelOverlayRef.hasAttached()
      ) {
        // Detach it
        this._tagsPanelOverlayRef.detach();

        // Reset the tag filter
        this.filteredTags = this.projects;

        // Toggle the edit mode off
        this.tagsEditMode = false;
      }

      // If template portal exists and attached...
      if (templatePortal && templatePortal.isAttached) {
        // Detach it
        templatePortal.detach();
      }
    });
  }

  selectProject = (project: any): void => {
    this.store.dispatch(new SetProject(project));
    this._tagsPanelOverlayRef.dispose();
  };

  setDefaultProject(): void {
    if (this.projectFromQuerySet === false) {
      this.route.queryParams.subscribe(params => {
        if (params && params.projectId) {
          const proj = this.projects.find(
            project => project.id === params.projectId,
          );
          if (proj) {
            this.selectedProject = proj;
            this.store.dispatch(new SetProject(proj));
            this.projectFromQuerySet = true;
          }
        }
      });
    }
    if (this.projects.length > 0 && !this.selectedProject.id) {
      this.store.dispatch(new SetProject(this.projects[0]));
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
