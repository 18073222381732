import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class UnsubscribeHandler implements OnDestroy {
  protected unsubscribe$: Subject<void> = new Subject();

  ngOnDestroy(): void {
    // this.unsubscribe$.next();
    // this.unsubscribe$.complete();
    this.unsubscribe$.unsubscribe();
  }
}
