import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { PaginatedResult } from '../interfaces/pagingated-result';

export interface Filter {
  field: string;
  operator: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class BaseService<T> {
  constructor(
    protected http: HttpClient,
    protected controller: string,
  ) {}

  protected get baseUrl(): string {
    return `${environment.apiBaseUrl}/${this.controller}`;
  }

  get(
    take: number = 10,
    skip: number = 0,
    sortField: string = '',
    sortDir: string = '',
    searchText: string = '',
    compositeFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    },
  ): Observable<PaginatedResult<T>> {
    const filters: Filter[] = [];
    this.appendFilters(filters, compositeFilter);
    const params = {
      skip,
      take,
      searchText,
      sortField,
      sortDir,
      filters: JSON.stringify(filters),
    };

    return this.http.get<PaginatedResult<T>>(`${this.baseUrl}`, { params });
  }

  getByFilters(
    compositeFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    },
  ): Observable<PaginatedResult<T>> {
    return this.get(10, 0, '', '', '', compositeFilter);
  }

  getById(id: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${id}`);
  }

  getBySearch(searchText: string = ''): Observable<PaginatedResult<T>> {
    return this.get(10, 0, '', '', searchText, null);
  }

  post(body: T): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}`, body);
  }

  put(id: string, body: T): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, body);
  }

  delete(id: string, partitionKey: string): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}/${id}?partitionKey=${partitionKey}`,
    );
  }

  protected appendFilters(
    filters: Filter[],
    compositeFilter: CompositeFilterDescriptor,
  ) {
    if (compositeFilter.filters.length === 0) {
      return;
    }

    for (const filter of compositeFilter.filters) {
      if ('logic' in filter) {
        this.appendFilters(filters, filter);
      } else {
        filters.push({
          field: filter.field as any,
          operator: filter.operator as any,
          value: filter.value,
        });
      }
    }
  }
}
