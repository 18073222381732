<div
  class="flex flex-wrap items-center sm:mr-[10px!important] sm:border-r sm:border-[rgba(0, 0, 0, 0.1)]"
>
  <!-- bg-gray-100
  dark:bg-gray-700   -->
  <div
    class="flex items-center justify-center sm:pr-4 pr-2 pl-2 rounded-full leading-9 cursor-pointer text-gray-500 dark:text-gray-300"
    (click)="openTagsPanel()"
    #tagsPanelOrigin
  >
    <ng-container>
      <mat-icon
        class="icon-size-5 sm:mr-3"
        svgIcon="mat_outline:grid_view"
      ></mat-icon>

      <span
        class="sm:ml-1.5 sm:inline-block hidden text-md font-medium whitespace-nowrap overflow-ellipsis overflow-hidden"
        >{{ selectedProject?.name }}</span
      >
    </ng-container>

    <!-- Tags panel -->
    <ng-template #tagsPanel>
      <div
        class="sm:w-60 w-[18rem] min-w-[18rem] rounded border shadow-md bg-card sm:min-w-100"
      >
        <!-- Tags panel header -->
        <div class="flex justify-between items-center m-3 mr-2">
          <div class="flex flex-grow items-center">
            <mat-icon
              class="icon-size-5"
              [svgIcon]="'heroicons_solid:search'"
            ></mat-icon>
            <div class="ml-2 flex-grow">
              <input
                class="w-full min-w-0 py-1 border-0"
                type="text"
                placeholder="Search Project"
                [maxLength]="30"
                #newTagInput
                [(ngModel)]="searchText"
              />
            </div>
          </div>
          <mat-icon
            (click)="searchText = ''; newTagInput.focus()"
            class="cursor-pointer icon-size-5"
            >close</mat-icon
          >
        </div>
        <div class="flex flex-col max-h-64 border-t overflow-y-auto">
          <!-- Tags -->
          <ng-container *ngIf="!tagsEditMode">
            <ng-container *ngFor="let project of projects | filter: searchText">
              <div
                class="flex items-center space-x-2 h-10 min-h-10 px-4 py-8 cursor-pointer hover:bg-hover border-b"
                (click)="selectProject(project)"
                matRipple
              >
                <img
                  class="project-icon w-12"
                  src="assets/images/logos/linq-logo.png"
                />

                <div class="flex flex-col justify-between">
                  <div style="font-size: 0.9rem">
                    {{ project.name }}
                  </div>

                  <span class="text-gray-400" style="font-size: 0.9rem">
                    {{ project.code }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</div>
